import React from 'react'
import headerConfig from "../../.forestry/content/settings/header.json"
import footerConfig from "../../.forestry/content/settings/footer.json"
import Layout from '../components/layout/layout'

export default () => {
  
  return (
    <section>
      <Layout notFound={true} headerConfig={headerConfig} footerConfig={footerConfig} />
    </section>
  )
}